.pricing-wizard {
    .progress {
        height: 7px;

        .progress-bar {
            background-color: #3597ee;
        }

        &.active {
            .progress-bar {
                width: 100%;
            }
        }
        
        &.current {
            .progress-bar {
                width: 100%;
                // animation: current-step 2s linear infinite;
            }
        }
    }

    &-headings {
        position: relative;
        height: 30px;
        overflow: hidden;

        span {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            display: block;    
            animation-duration: 1s;
            animation-fill-mode: both;

            &[class*='Out'] {
                opacity: 0;
            }
        }
    }
}

.fadeInRight {
  animation-name: fadeInRight;
}

.fadeOutLeft {
    animation-name: fadeOutLeft;
}

.fadeInDown {
  animation-name: fadeInDown;
}

.fadeOutDown {
  animation-name: fadeOutDown;
} 

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes current-step {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}


@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}


@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

