

.wave-container {
    position: relative;
    text-align: center;
    overflow: hidden;
    pointer-events: none;

    &.wave-absolute {
          position: absolute;
          top: auto;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 0;
      
          width: 100%;
          // height: 100%;
    }

    &> svg {
        display: block;
    }

    &.wave-style-4 {
        position: absolute;
        padding-bottom: 6%;
        width: 100%;
        z-index: 5;
        height: 100%;
    }

    &.wave-animated {
        animation: animateWave 1000ms cubic-bezier(0.23, 1, 0.32, 1) forwards infinite;
    }
}

 @keyframes animateWave {
    0% {
      transform: scale(1,0);
    }
    100% {
      transform: scale(1,1);
    }
  }


.animated-waves {
    position: relative;
    width: 100%;
    height: 16vh;
    margin-bottom: -7px;
    min-height: 100px;
    max-height: 150px;

    @media (max-width:767.98px){
      .animated-waves{
        height:40px;
        min-height:40px
      }
    }

    .moving-waves > use {
      animation: moving-waves 40s cubic-bezier(.55,.5,.45,.5) infinite;
    }

    .moving-waves>use:nth-child(2){animation-delay:-4s;animation-duration:13s}
    .moving-waves>use:nth-child(3){animation-delay:-3s;animation-duration:15s}
    .moving-waves>use:nth-child(4){animation-delay:-4s;animation-duration:20s}
    .moving-waves>use:nth-child(5){animation-delay:-4s;animation-duration:25s}
    .moving-waves>use:nth-child(6){animation-delay:-3s;animation-duration:30s}
}

@keyframes moving-waves{
  0%{transform:translate3d(-90px,0,0)}
  to{transform:translate3d(85px,0,0)}
}