

footer {
    // background-color: #f9fafc;
    padding-top: 100px;

    .footer-logo {
        max-width: 150px;
    }

    .list-group-item {
        border: 0;
        background-color: transparent;
        a {
            color: inherit;
        }
    }
}