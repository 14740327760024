

.product-card {

    .card:not(.border-0):not(.card-category) .badge-floating {
        left: -1px;
    }

    .card {
        position: relative;
        overflow: hidden;
    }

    .badge {
        font-size: 0.95em;
    }

    .btn {
        &:focus {
            outline: none;
            // box-shadow: none;
        }
    }
}

.badge-floating:not(.badge-floating-end) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.badge-floating {
    position: absolute;
    top: 1.5rem;
    left: 0;
    z-index: 5;
    font-weight: 400 !important;
}

