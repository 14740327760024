

.device-spec-icons {
    display: inline-block;
    margin-right: 10px;
    min-width: 20px;
    width: 20px;

    left: 10px;

    img {
        max-height: 20px;
        max-width: none;
        width: 20px;
    }
}

.device-spec-list {
    .list-group-item {
        padding: .75em 1em;
        background-color: transparent;
        border-color: transparent;
    }
}


.product-header-nav.nav-pills {
    .nav-link{
        background-color: rgba($color: #fff, $alpha: 0.6);
        border-color: rgba(255,255,255,0.14);
        border-radius: 1rem;
        padding: 1em;
        color: #5a5b75;

        &.active {
            background-color: #fff;
            color: #5a5b75;
            box-shadow: 0.1875rem 0.1875rem 0.625rem -0.125rem rgba($color: #000, $alpha: 0.15);
        }
    }
}