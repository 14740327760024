

#hero-section {
    height: 100vh;
    min-height: 800px;
    width: 100%;
    position: relative;
    z-index: 2;

    @media (max-width: 576px) {
        height: auto;
        min-height: 600px;
    }

    .hero-logo {
        max-width: 150px;
    }
}