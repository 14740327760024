

.cloudimage-360 .cloudimage-360-prev,
.cloudimage-360 .cloudimage-360-next {
  padding: 8px;
  background: rgb(244, 244, 244);
  border: none;
  border-radius: 4px;
}

.cloudimage-360 .cloudimage-360-prev:focus,
.cloudimage-360 .cloudimage-360-next:focus {
  outline: none;
}

.cloudimage-360 .cloudimage-360-prev {
  display: none;
  position: absolute;
  z-index: 100;
  top: calc(50% - 15px);
  left: 20px;
}

.cloudimage-360 .cloudimage-360-next {
  display: none;
  position: absolute;
  z-index: 100;
  top: calc(50% - 15px);
  right: 20px;
}

.cloudimage-360 .cloudimage-360-prev:before,
.cloudimage-360 .cloudimage-360-next:before {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background: 50% 50% / cover no-repeat;
}

.cloudimage-360 .cloudimage-360-prev:before {
  background-image: url("./arrow-left.svg");
}

.cloudimage-360 .cloudimage-360-next:before {
  background-image: url("./arrow-right.svg");
}

.cloudimage-360 .cloudimage-360-prev.not-active,
.cloudimage-360 .cloudimage-360-next.not-active {
  opacity: 0.4;
  cursor: default;
}
