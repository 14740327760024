
@import '../../sass/variables.scss';

.cs-shape {
	position: absolute;
    width: 100%;
    overflow: hidden;
    z-index: 2;
	pointer-events: none;
	
	svg {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		bottom: 0;
	}

	&-curve {
		padding-bottom: 6.2%;
	}
	

    &-top, 
    &-slope-right {
        top: 0;
        margin-top: -1px;
    }

	&-bottom {
		bottom: 0;
		margin-bottom: -1px;
	}

	&-top,
	&-slope-left,
	&-slope-right, 
	&-bottom {
		width: 100%;
		left: 0;
	}

	&-atlassian,
	&-slope-left,
	&-slope-right {	
		// height: 245px;
		// transform: translateY(-100%);
		top:auto;
		right: 0;
		bottom: 0;
		padding: 0;
		
		svg {
			width: 100%;
			height: auto;
			transform: scale(2);
			overflow: hidden;
			transform-origin: top center;
			display: block;
			position: relative;
			top:auto;
			left: auto;
			bottom: auto;
		}
	}

	&-atlassian {
		svg {
			position: relative;
			transform: scale(1);
			transform-origin: center;
			z-index: 5;
		}
	}

	&-atlassian-inverse {
		svg {
			position: relative;
			transform: scale(-1);
			transform-origin: center;
			z-index: 5;
		}
	}

	&.color-body {
		color: $appBgColor !important;
	}

	&.color-secondary {
		color: $appBgSecondaryColor !important;
	}
	&.color-ounch {
		color: lighten($appOunchColor, 30) !important;
	}
}