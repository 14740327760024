

.header-section {
    height: 50vh;
    min-height: 500px;
}

.terms-text {
    li {
        margin-bottom: 1rem !important;

        > strong {
            display: block;
            margin-bottom: 1rem;
        }

        > ol {
            padding-top: .5em;
        }
    }
}