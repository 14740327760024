/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    src: local('Poppins Regular'), url('#{$fontPaths}/Poppins-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: normal;
    src: local('Poppins Italic'), url('#{$fontPaths}/Poppins-Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    src: local('Poppins Thin'), url('#{$fontPaths}/Poppins-Thin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 100;
    src: local('Poppins Thin Italic'), url('#{$fontPaths}/Poppins-ThinItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    src: local('Poppins ExtraLight'), url('#{$fontPaths}/Poppins-ExtraLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 200;
    src: local('Poppins ExtraLight Italic'), url('#{$fontPaths}/Poppins-ExtraLightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    src: local('Poppins Light'), url('#{$fontPaths}/Poppins-Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    src: local('Poppins Light Italic'), url('#{$fontPaths}/Poppins-LightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: local('Poppins Medium'), url('#{$fontPaths}/Poppins-Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    src: local('Poppins Medium Italic'), url('#{$fontPaths}/Poppins-MediumItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: local('Poppins SemiBold'), url('#{$fontPaths}/Poppins-SemiBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    src: local('Poppins SemiBold Italic'), url('#{$fontPaths}/Poppins-SemiBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: local('Poppins Bold'), url('#{$fontPaths}/Poppins-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    src: local('Poppins Bold Italic'), url('#{$fontPaths}/Poppins-BoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    src: local('Poppins ExtraBold'), url('#{$fontPaths}/Poppins-ExtraBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 800;
    src: local('Poppins ExtraBold Italic'), url('#{$fontPaths}/Poppins-ExtraBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: local('Poppins Black'), url('#{$fontPaths}/Poppins-Black.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 900;
    src: local('Poppins Black Italic'), url('#{$fontPaths}/Poppins-BlackItalic.woff') format('woff');
    }